import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import {
  Form,
  Link,
  useActionData,
  useLoaderData,
  useSearchParams,
} from "@remix-run/react";
import { badRequest } from "~/utils/request.server";
import { createUserSession, getUser } from "~/utils/session.server";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import { WebDIContainer } from "~/di-containers/web.di-container.server";
import { useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { tvField } from "~/utils/global-tailwind-variants.utils";
import { tv } from "tailwind-variants";
import { RedirectTo } from "~/utils/validation.utils.server";
import { getValidURLWithRedirectToSearchParam } from "~/utils/redirect-to.utils.server";

export const meta: MetaFunction = () => [
  {
    title: "Revyse | Login",
  },
  {
    name: "description",
    content: "Login to access your account!",
  },
  {
    name: "image",
    property: "og:image",
    content: "/assets/open-graph-image.png",
  },
];

function validateEmail(username: unknown) {
  if (typeof username !== "string" || username.length < 3) {
    return `Usernames must be at least 3 characters long`;
  }
}

function validatePassword(password: unknown) {
  if (typeof password !== "string" || password.length < 6) {
    return `Passwords must be at least 6 characters long`;
  }
}

export const action = async ({ request }: ActionFunctionArgs) => {
  const form = await request.formData();
  const email = form.get("email");
  const password = form.get("password");

  const { auditLogService } = await WebDIContainer();

  const redirectToParam = form.get("redirectTo");
  const redirectToValidation =
    RedirectTo.default("/").safeParse(redirectToParam);
  const redirectTo = redirectToValidation.success
    ? redirectToValidation.data
    : "/";

  if (typeof email !== "string" || typeof password !== "string") {
    return badRequest({
      fieldErrors: null,
      fields: null,
      formError: `Form not submitted correctly.`,
    });
  }

  const fields = { email, password };
  const fieldErrors = {
    email: validateEmail(email),
    password: validatePassword(password),
  };
  if (Object.values(fieldErrors).some(Boolean)) {
    return badRequest({
      fieldErrors,
      fields,
      formError: null,
    });
  }

  const { authService } = await WebDIContainer();
  const user = await authService.findUserByEmailPassword({
    email,
    password,
  });
  if (!user) {
    const message = `Username/Password combination is incorrect`;
    await auditLogService.logAction({
      action: "LoginAttempt",
      user_id: null,
      resource_id: null,
      payload: { email, message },
      operation: async () => false,
    });

    return badRequest({
      fieldErrors: null,
      fields,
      formError: message,
    });
  }

  if (user.locked) {
    const message = `Maximum login attempts exceeded. Your account has been locked. Please change your password to unlock your account.`;
    await auditLogService.logAction({
      action: "LoginAttempt",
      user_id: null,
      resource_id: null,
      payload: { email, message },
      operation: async () => false,
    });

    return badRequest({
      fieldErrors: null,
      fields,
      formError: message,
    });
  }

  await auditLogService.logAction({
    action: "LoginAttempt",
    user_id: null,
    resource_id: null,
    payload: { email },
    operation: async () => true,
  });

  return createUserSession(user.id, redirectTo);
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const user = await getUser(request);

  const { microsoftClient } = await WebDIContainer();

  const microsoftLoginLink = microsoftClient.getLoginLink();

  const validUrl = getValidURLWithRedirectToSearchParam(request.url);
  if (validUrl) {
    return redirect(validUrl);
  }

  return json({ user, microsoftLoginLink });
};

const tvSignInButton = tv({
  base: "group relative flex w-full justify-center rounded-md py-2 px-3 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600",
  slots: {
    icon: "absolute inset-y-0 left-0 flex items-center ml-3",
  },
});

export default function LoginRoute() {
  const { microsoftLoginLink } = useLoaderData<typeof loader>();
  const actionData = useActionData<typeof action>();
  const [searchParams] = useSearchParams();

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  const handleLoginWithMicrosoft = () => {
    if (microsoftLoginLink) {
      localStorage.setItem(
        "microsoftLoginState",
        new URLSearchParams(new URL(microsoftLoginLink).search).get("state") ??
          ""
      );
      const redirectTo = searchParams.get("redirectTo") ?? undefined;
      if (redirectTo) {
        localStorage.setItem("loginRedirectTo", redirectTo);
      } else {
        localStorage.removeItem("loginRedirectTo");
      }
      window.location.href = microsoftLoginLink;
    }
  };

  return (
    <>
      {/*
      This example requires updating your template:

      ```
      <html class="h-full bg-gray-50">
      <body class="h-full">
      ```
    */}
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src="/assets/revyse-logo-color-black.png"
              alt=""
              width="320"
              height="112"
            />
            <h1 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Sign in to your account
            </h1>
            <p className="mt-2 text-center text-sm text-gray-600">
              Or{" "}
              <Link
                id="or-sign-up-link"
                to={
                  searchParams.get("redirectTo")?.includes("sign-up")
                    ? "../sign-up"
                    : `../sign-up?redirectTo=${encodeURIComponent(
                        searchParams.get("redirectTo") ?? ""
                      )}`
                }
                className="font-medium text-sky-600 hover:text-sky-500"
              >
                sign up
              </Link>
            </p>
          </div>
          <Form className="mt-8 space-y-6" method="post" id="login-form">
            <input
              type="hidden"
              name="redirectTo"
              value={searchParams.get("redirectTo") ?? undefined}
            />
            <input type="hidden" name="loginType" defaultValue="login" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Work Email Address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="text"
                  autoComplete="email"
                  // required
                  className={tvField({
                    className: "relative rounded-none rounded-t-md",
                    error: !!actionData?.fieldErrors?.email,
                  })}
                  placeholder="Work Email Address"
                  defaultValue={actionData?.fields?.email}
                  aria-invalid={Boolean(actionData?.fieldErrors?.email)}
                  aria-errormessage={
                    actionData?.fieldErrors?.email ? "email-error" : undefined
                  }
                />
                {actionData?.fieldErrors?.email && (
                  <p
                    className="form-validation-error"
                    role="alert"
                    id="email-error"
                  >
                    {actionData.fieldErrors.email}
                  </p>
                )}
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <div className="relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    // required
                    className={tvField({
                      className: "relative rounded-none rounded-b-md pr-10",
                      error: !!actionData?.fieldErrors?.email,
                    })}
                    placeholder="Password"
                    defaultValue={actionData?.fields?.password}
                    aria-invalid={Boolean(actionData?.fieldErrors?.password)}
                    aria-errormessage={
                      actionData?.fieldErrors?.password
                        ? "password-error"
                        : undefined
                    }
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center pr-3 z-10"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <EyeSlashIcon
                        className="h-5 w-5 text-gray-400 hover:text-gray-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <EyeIcon
                        className="h-5 w-5 text-gray-400 hover:text-gray-500"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </div>
                {actionData?.fieldErrors?.password ? (
                  <p
                    className="form-validation-error"
                    role="alert"
                    id="password-error"
                  >
                    {actionData.fieldErrors.password}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="flex justify-end">
              <div className="text-sm">
                <Link
                  id="forgot-password-link"
                  to="/forgot-password"
                  className="font-medium text-sky-600 hover:text-sky-500"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div>
              {actionData?.formError && (
                <div
                  id="errors-wrong-combination"
                  className="text-red-500 my-1 rounded min-h-1"
                  role="alert"
                >
                  {actionData.formError}
                </div>
              )}
              <div className="space-y-4">
                <button
                  type="submit"
                  className={tvSignInButton().base({
                    className: "bg-sky-600 text-white hover:bg-sky-500",
                  })}
                >
                  <span className={tvSignInButton().icon()}>
                    <LockClosedIcon
                      className="size-5 text-sky-500 group-hover:text-sky-400"
                      aria-hidden="true"
                    />
                  </span>
                  Sign in
                </button>
                {microsoftLoginLink && (
                  <button
                    type="button"
                    className={tvSignInButton().base({
                      className:
                        "bg-white border border-gray-600 text-gray-800 hover:bg-gray-50",
                    })}
                    onClick={handleLoginWithMicrosoft}
                  >
                    <span className={tvSignInButton().icon()}>
                      <img
                        src="/assets/microsoft-logo.webp"
                        alt="Microsoft Logo"
                        className="size-5"
                        aria-hidden="true"
                      />
                    </span>
                    Sign in with Microsoft
                  </button>
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
